import { Box, Stack, Typography, Container, useTheme } from "@mui/material";
import React from "react";
import letterHighlight from "../images/hero-header/highlightLetter.svg";
// import orangeBlur from "../images/hero-header/orangeBlur.svg";
// import greenCircle from "../images/hero-header/greenCircle.svg";
// import yellowCross from "../images/hero-header/yellowCross.svg";
// import blueCross from "../images/hero-header/blueCross.svg";
import bgUrl from "../images/hero-header/staticBackground.svg";
import calendar from "../images/calendar.svg";
import PortableTextComponent from "./PortableTextComponent";
import { Helmet } from "react-helmet";

const ArticlePage = ({ title, author, lastUpdated, content, seoDesc, articleImg }) => {
  const theme = useTheme();
  const date = new Date(lastUpdated);
  const options = { year: "numeric", month: "short", day: "2-digit" };
  lastUpdated = new Intl.DateTimeFormat("en-US", options).format(date);

  return (
    <Box
      sx={{
        backgroundImage: `url(${bgUrl})`,
        paddingBottom: 5, // Adjust padding to avoid footer overlap
      }}
    >
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={seoDesc} />
        <meta name="author" content={author} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={seoDesc} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={articleImg} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={seoDesc} />
        <meta name="twitter:image" content={articleImg} />
      </Helmet>
      <Box sx={{ maxWidth: "lg", mx: "auto" }} position={"relative"} paddingTop={5}>
        <Container>
          <Stack
            position={"relative"}
            spacing={4}
            direction={"column"}
            sx={{ borderBottom: "2px solid #9DA8BC", pb: 10 }}
          >
            <header>
              <Stack spacing={2}>
                <Typography
                  color={theme.palette.text.header}
                  whiteSpace={"normal"}
                  variant="h3"
                  component={"h1"}
                  sx={{
                    lineHeight: 1.2,
                    textTransform: "capitalize",
                  }}
                >
                  <Box position={"relative"}>
                    {title.split(" ").slice(0, -2).join(" ")}{" "}
                    <Box component={"span"} sx={{ color: "#EA552B" }}>
                      {title.split(" ").slice(-2).join(" ")}
                    </Box>
                    <Box
                      component={"img"}
                      src={letterHighlight}
                      alt={"decorative highlight"}
                      sx={{ position: "absolute", top: "-55px", left: "-40px" }}
                      loading="lazy"
                    />
                  </Box>
                </Typography>
                <Box display={"flex"} alignItems={"center"} gap={2}>
                  <Typography color={theme.palette.text.description} whiteSpace={"normal"} variant="title2">
                    By {author}
                  </Typography>
                  <Box display={"flex"} alignItems={"center"} gap={"6px"}>
                    <Box component={"img"} src={calendar} loading="lazy" alt="calendar logo" />
                    <Typography variant="subtitleLight" color={theme.palette.text.description} component="p">
                      Updated {lastUpdated}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </header>
          </Stack>
          <Box
            display={"flex"}
            sx={{ flexDirection: { xs: "column", lg: "row" } }}
            gap={8}
            mt={6}
            justifyContent={"space-between"}
            color={theme.palette.text.description2}
          >
            <article>
              <PortableTextComponent props={content} />
            </article>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ArticlePage;
